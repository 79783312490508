<template>
    <div id="baseInfoIndexPage">
      <ul class="list">
        <li class="list-item">
          <div class="left">プロフィル写真</div>
          <div class="right">
            <div class="value">
              <van-uploader :after-read="afterRead">
                <van-image
                  round
                  class="avatar"
                  fit="cover"
                  :src="personInfo.avatarTemp || require('@/assets/images/avatarDefault_01.png')"
                />
              </van-uploader>
            </div>
            <van-icon class="icon-arrow" name="arrow" />
          </div>
        </li>
        <li
          @click="onEdit({title:'ニックネームを変更',value: personInfo.name,placeholder: 'ニックネームを入力してください', key:'name'})"
          class="list-item"
        >
          <div class="left">ニックネーム</div>
          <div class="right">
            <div class="value">{{personInfo.name || ''}}</div>
            <van-icon class="icon-arrow" name="arrow" />
          </div>
        </li>
        <li class="list-item">
          <div class="left">アカウント</div>
          <div class="right">
            <div class="value">{{ $utils.storage.get('email') || $utils.storage.get('loginToken').email || '' }}</div>
          </div>
        </li>
      </ul>
      <EditInfoPop ref="editInfoPop" v-model="visible" @save="onSave"></EditInfoPop>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Uploader } from 'vant';
import { EditInfoPop } from '@/components';
// import { mapGetters } from 'vuex';
export default {
  name: 'BaseInfoIndex',
  components: {
    'van-uploader': Uploader,
    EditInfoPop
  },
  data () {
    return {
      visible: false
    };
  },
  created () {
  },
  mounted () {
  },
  computed: {

    /* 获取用户信息 */
    ...mapGetters(['personInfo'])
  },
  watch: {},
  methods: {
    onEdit (params) {
      this.$refs.editInfoPop.init(params);
    },
    onSave (params) {
      const { $utils, personInfo } = this;
      personInfo[params.key] = params.value;
      this.$http.post('mine/modifyUser', {
        name: personInfo[params.key]
      }).then((res) => {
        $utils.successToast('修正成功');
      });
      this.visible = false;
    },
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      this.personInfo.avatar = file.content;
      this.$utils.loadingToast('アップロード中...');
      const formData = new FormData();
      formData.append('file', file.file);
      this.$http.post('upload/image/avatar', formData).then((res) => {
        this.$http.post('mine/modifyUser', {
          avatar: res.data.fileName
        }).then((res) => {
          this.$utils.successToast('アップロード成功');
          this.$http.get('mine/information').then((res) => {

            /* 改变头像路径 */
            this.personInfo.avatarTemp = res.data.avatarTemp;
          });
        });
      });
    }
  }
};
</script>

<style scoped lang="less">
  #baseInfoIndexPage {
    .list {
      font-size: 26px;
      line-height: 34px;
      color: #fff;

      .list-item {
        position: relative;
        min-height: 112px;
        padding: 32px 28px;

        .left {
          flex-shrink: 0;
          opacity: 0.8;
        }

        &,
        .right {
          display: flex;
          align-items: center;
        }

        .right {
          position: relative;
          flex: 1;
          justify-content: flex-end;
          padding-right: 42px;

          .value {
            .avatar {
              width: 96px;
              height: 96px;
            }
          }

          .icon-arrow {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }

        &::after {
          position: absolute;
          bottom: 0;
          left: 28px;
          width: calc(100% - 56px);
          height: 1px;
          content: '';
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }
</style>